import { VenueModel } from '@/venues/models';
import { ClientModel } from '@/clients/models';

export enum EventType {
    KICKOFF = 'Kickoff',
    ONBOARDING = 'Onboarding',
    CHAPTER = 'Chapter',
    SPECIAL = 'Special',
}

export enum EventSubtype {
    SPECIAL = 'Special',
    TOWN_HALL = 'Town Hall',
    AMBASSADOR = 'Ambassador',
}

export enum EventLanguage {
    EN = 'EN',
    ES = 'ES',
}
export interface FacilitatorModel {
    email: string;
    firstName: string;
    lastName: string;
    photo: string;
    phone: string;
}
export interface MemberModel {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    photo: string;
}

export interface EventModel {
    id: string;
    name: string;
    description: string;
    type: EventType;
    startDateInMilliseconds: number;
    endDateInMilliseconds: number;
    language: EventLanguage | undefined;
    clients: string[];
    campaigns: string[];
    imageUrl: string;
    venue: VenueModel;
    isAuthenticatedUserRegistered: boolean;
    timezoneToDisplay: string;
    facilitator: FacilitatorModel;
    member: MemberModel;
    numberOfAttendees: number;
    upcomingWeeklyEvents: EventModel[];
    distance: number;
    subtype: EventSubtype;
}

export type EventFiltersModel = {
    usState: string | undefined;
    zipCode: string | undefined;
    language: string | undefined;
    client: ClientModel | undefined;
};
