import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import { Checkbox } from '@/components/form-controls/checkbox';
import { Button, ButtonType } from '@/components/form-controls/button';
import { useContainerDimensions } from '@/hooks/use-container-dimensions';
import { useClickListener } from '@/components/form-controls/common-control-hooks/use-click-listener';
import { TooltipContent } from './tooltip-content';
import './tooltip.css';
import isEqual from 'lodash/isEqual';

export interface DropdownOption {
    value: string;
    label: string;
}

export type CustomUIOptions = {
    top?: number;
    position?: string;
};

type Props = {
    label: string;
    preselectedOptions: string[];
    options: DropdownOption[];
    onChange: (option: DropdownOption[]) => void;
    firstOptionRepresentsAllOptions: boolean;
    uiOptions?: CustomUIOptions;
    onMenuOpened?: () => void;
    disabled?: boolean;
    disableMessage?: string;
};

export const Dropdown: FunctionComponent<Props> = ({
    label,
    preselectedOptions,
    options,
    onChange,
    firstOptionRepresentsAllOptions,
    uiOptions = {},
    onMenuOpened,
    disabled = false,
    disableMessage,
}) => {
    const [optionsSelected, setOptionsSelected] = useState<string[]>(preselectedOptions);
    const [open, setOpen] = useState<boolean>(false);
    const [isTooltipDisplayed, displayTooltip] = useState(false);
    const containerRef = useRef(null);
    const menuContainerRef = useRef(null);

    const { width: containerWidth } = useContainerDimensions(containerRef);

    const labelOptionSelected = useMemo(() => {
        const optionsFound = options.filter((option) => preselectedOptions.includes(option.value));
        return optionsFound.map((option) => option.label).join(', ');
    }, [preselectedOptions, options]);

    const checkboxValues = useMemo(() => {
        return options.map((item) => optionsSelected.includes(item.value));
    }, [options, optionsSelected]);

    // @ts-ignore
    const dropdownContainerCustomStyle: React.CSSProperties = useMemo(
        () => ({
            width: containerWidth,
            top: uiOptions.top || 75,
            position: uiOptions.position || 'absolute',
        }),
        [containerWidth, uiOptions.top, uiOptions.position]
    );

    const changeHandler = useCallback(
        (index: number) => {
            if (firstOptionRepresentsAllOptions && index === 0) {
                setOptionsSelected([options[0].value]);
                return;
            }

            const newOptionsToSelect = options.filter((option, optionIndex) => {
                if (optionIndex === 0) {
                    return false;
                }

                if (optionIndex === index) {
                    return !optionsSelected.includes(option.value);
                }

                return optionsSelected.includes(option.value);
            });

            setOptionsSelected(newOptionsToSelect.map((option) => option.value));
        },
        [firstOptionRepresentsAllOptions, options, setOptionsSelected, optionsSelected]
    );

    const clearHandler = useCallback(() => {
        setOptionsSelected([options[0].value]);
        onChange([options[0]]);
        setOpen(false);
    }, [onChange, options, setOptionsSelected]);

    const submitHandler = useCallback(() => {
        onChange(options.filter((option) => optionsSelected.includes(option.value)));
        setOpen(false);
    }, [onChange, options, optionsSelected]);

    const clickHandler = useCallback(() => {
        if (disabled) {
            return;
        }

        setOpen(!open);

        if (onMenuOpened) {
            onMenuOpened();
        }

        if (!isEqual(preselectedOptions, optionsSelected)) {
            setOptionsSelected(preselectedOptions);
        }
    }, [open, onMenuOpened, preselectedOptions, optionsSelected, disabled]);

    const getCheckboxesList = useCallback(() => {
        const copyOfCheckboxValues = firstOptionRepresentsAllOptions
            ? checkboxValues.slice(1)
            : checkboxValues;

        const copyOfOptions = firstOptionRepresentsAllOptions ? options.slice(1) : options;
        const surplus = firstOptionRepresentsAllOptions ? 1 : 0;

        return copyOfCheckboxValues.map((checkboxValue, index) => (
            <div key={copyOfOptions[index].value}>
                <Checkbox
                    id={copyOfOptions[index].value}
                    label={copyOfOptions[index].label}
                    value={checkboxValue}
                    customContainerClassName={`${Styles.Option}`}
                    customLabelClassName={Styles.label}
                    onChange={() => changeHandler(index + surplus)}
                />
            </div>
        ));
    }, [checkboxValues, options, firstOptionRepresentsAllOptions, changeHandler]);

    const onTooltipClose = useCallback(() => {
        displayTooltip(false);
    }, []);

    const onTooltipOpen = useCallback(() => {
        displayTooltip(true);
    }, []);

    const renderTooltip = (props: any) => {
        return (
            <Tooltip {...props} id="tooltip-container">
                <TooltipContent label={disableMessage as string} onClose={onTooltipClose} />
            </Tooltip>
        );
    };

    useClickListener(containerRef, menuContainerRef, clickHandler, open);

    useEffect(() => {
        if (preselectedOptions) {
            setOptionsSelected(preselectedOptions);
        }
    }, [preselectedOptions, setOptionsSelected]);

    return (
        <div
            ref={containerRef}
            className={`${Styles.DropdownContainer} d-flex flex-column align-items-start`}
        >
            <p className={`${Styles.label} mb-1`}>{label}</p>

            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
                show={disabled && isTooltipDisplayed}
            >
                <div
                    className={`${Styles.Dropdown} ${disabled && Styles.Disabled} d-flex justify-content-between align-items-center w-100 h-100`}
                    onClick={clickHandler}
                    onMouseEnter={() => onTooltipOpen()}
                    onMouseLeave={() => onTooltipClose()}
                >
                    <div className={`${Styles.DropdownSelectedValue}`}>
                        <span>{labelOptionSelected}</span>
                    </div>

                    <img
                        className={`${open ? Styles.ArrowUp : Styles.ArrowDown}`}
                        alt={'arrow-down-icon'}
                        src={IMAGES.ArrowDownIcon}
                    />
                </div>
            </OverlayTrigger>

            <div
                ref={menuContainerRef}
                className={`${Styles.DropdownOptionsContainer} ${open && Styles.Opened}`}
                style={dropdownContainerCustomStyle}
            >
                {firstOptionRepresentsAllOptions && (
                    <div key={options[0].value}>
                        <Checkbox
                            id={options[0].value}
                            label={options[0].label}
                            value={checkboxValues[0]}
                            customContainerClassName={`${Styles.Option} ${Styles.general}`}
                            customLabelClassName={Styles.label}
                            onChange={() => changeHandler(0)}
                        />
                    </div>
                )}

                {getCheckboxesList()}

                <Row className={`mx-0 pb-2 pt-2 ${Styles.Actions}`}>
                    <Col className={`d-flex justify-content-evenly `}>
                        <p
                            className={`${Styles.ClearAction} py-2 w-100 m-0 d-flex align-items-center`}
                            onClick={clearHandler}
                        >
                            Clear
                        </p>

                        <span className={'px-2'} />

                        <Button
                            type={ButtonType.PRIMARY_BLUE_1}
                            onClick={submitHandler}
                            customClassName={`py-2 w-100`}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
