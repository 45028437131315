import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticatedUserModel } from '@/authentication/models';
import { initialAuthenticationState, QueryParameters } from '@/authentication/reducer/state';

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthenticationState,
    reducers: {
        setLoadingAuthentication: (state, action: PayloadAction<boolean>) => {
            state.isLoadingAuthentication = action.payload;
        },
        storeAuthenticatedUser: (state, action: PayloadAction<AuthenticatedUserModel>) => {
            state.authenticatedUser = action.payload;
        },
        storeSessionId: (state, action: PayloadAction<string>) => {
            state.sessionId = action.payload;
        },
        storeQueryParameters: (state, action: PayloadAction<QueryParameters>) => {
            state.queryParameters = action.payload;
        },
    },
});

export const authenticationReducer = authenticationSlice.reducer;
export const authenticationActions = authenticationSlice.actions;
