import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useQueryParams } from '@/utilities/use-query-params';
import { EventRegistration } from '@/events/pages/event-registration';
import { AllEventsPage } from '@/events/pages/all-events';
import { useAuthorization } from '@/authentication/hooks/use-authorization';
import { EventPageWrapperConnectorProps } from '@/events/pages/event-page-wrapper-connector';
import { useUrlUpdater } from '@/hooks/use-url-updater';
import { useInitializationFromUrl } from '@/events/hooks/use-initialization-from-url';
import { useDispatch } from 'react-redux';
import { eventsActions } from '@/events/reducer';
import { RegistrationModal } from '@/contacts/components/registration-modal';
import { RegistrationStatus } from '@/events/reducer/types';
import { useSelectionOfEvent } from '@/events/hooks/use-selection-of-event';

export const EventPageWrapperComponent: FunctionComponent<EventPageWrapperConnectorProps> = ({
    authenticatedUser,
    storeQueryParameters,
    queryParameters,
    storeAuthenticatedUser,
    fetchClients,
    isLoadingClients,
    selectedEvent,
    registrationStatus,
}) => {
    useUrlUpdater();

    const { getQueryParameter } = useQueryParams();

    const dispatch = useDispatch();

    const { isLoadingAuthentication, clearToken } = useAuthorization({ storeAuthenticatedUser });

    const updateQueryParametersInsideState = useCallback(
        (clientName: string): void => {
            const campaignsParameter: string[] = getQueryParameter('campaign')?.split(';') || [];
            const eventIdParameter: string | undefined = getQueryParameter('event');
            const stateParameter: string | undefined = getQueryParameter('state');

            storeQueryParameters({
                clients: [clientName],
                campaigns: campaignsParameter,
                eventId: eventIdParameter,
                state: stateParameter,
            });
        },
        [storeQueryParameters, getQueryParameter]
    );

    const { handleClientParamInUrl, clientFilterInitialized } = useInitializationFromUrl({
        updateQueryParametersInsideState,
    });

    useSelectionOfEvent();

    const showAllEventsPage = useCallback(() => {
        if (selectedEvent) {
            dispatch(eventsActions.selectEvent(undefined));
        }

        if (queryParameters && queryParameters.eventId) {
            storeQueryParameters({
                ...queryParameters,
                clients: [],
                eventId: undefined,
            });
        }
    }, [queryParameters, storeQueryParameters, selectedEvent]);

    const onOpeningAuthenticationModal = useCallback(
        (callback: () => void) => {
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.AUTHENTICATING));
        },
        [dispatch]
    );

    const onClosingAuthenticationModal = useCallback(
        () => dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.CONFIRMING)),
        [dispatch]
    );

    useEffect(() => {
        fetchClients();
    }, [fetchClients]);

    useEffect(() => {
        if (isLoadingAuthentication || isLoadingClients) {
            return;
        }

        handleClientParamInUrl();

        if (!authenticatedUser?.client) {
            clearToken();
            return;
        }
    }, [authenticatedUser, clearToken, isLoadingAuthentication, isLoadingClients]);

    useEffect(() => {
        const campaignsParameter: string[] = getQueryParameter('campaign')?.split(';') || [];

        if (campaignsParameter.length !== 0) {
            dispatch(eventsActions.updateCampaignsFilter(campaignsParameter));
        }
    }, []);

    return (
        <>
            <AllEventsPage
                isLoadingAuthentication={isLoadingAuthentication}
                isClientFilterInitialized={clientFilterInitialized}
            />

            {selectedEvent &&
                [
                    RegistrationStatus.VIEWING_DETAILS,
                    RegistrationStatus.CONFIRMING,
                    RegistrationStatus.AFTER_CONFIRMATION,
                    RegistrationStatus.WHAT_IS_NEXT,
                ].includes(registrationStatus) && (
                    <EventRegistration
                        showBackAllEvents={showAllEventsPage}
                        openAuthenticationModal={onOpeningAuthenticationModal}
                    />
                )}

            {registrationStatus === RegistrationStatus.AUTHENTICATING && (
                <RegistrationModal onAuthenticated={onClosingAuthenticationModal} />
            )}
        </>
    );
};
