import React, { useCallback, useEffect } from 'react';
import CryptoJS from 'crypto-js';
// eslint-disable-next-line import/named
import { v4 as generateUUIDV4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { useQueryParams } from '@/utilities/use-query-params';
import { ELIGIBILITY_CHECKER_QUERY_KEY } from '@/constants/query-params';
import { getNewToken } from '@/authentication/api';
import { IMAGES } from '@/assets/images';
import Styles from './Styles.module.scss';
import Col from 'react-bootstrap/Col';
import { TOKEN_PROP_NAME } from '@/constants/local-storage';

export const EligibilityCheckerPage = () => {
    const { getQueryParameter } = useQueryParams();
    const navigate = useNavigate();

    const processMember = useCallback(async (contactId: string) => {
        const newToken = await getNewToken(contactId, generateUUIDV4());
        localStorage.setItem(TOKEN_PROP_NAME, newToken);
        navigate('/');
    }, []);

    const convertTokenToBase64 = useCallback((token: string) => {
        let base64 = token.replace(/-/g, '+').replace(/_/g, '/');

        while (base64.length % 4) {
            base64 += '=';
        }
        return base64;
    }, []);

    const processToken = useCallback(
        (token: string) => {
            const secretPasscode = process.env.REACT_APP_ELIGIBILITY_CHECKER_PASSCODE || '';

            const tokenBase64 = convertTokenToBase64(token);
            const tokenDecrypted = CryptoJS.AES.decrypt(tokenBase64, secretPasscode);

            const contactId = tokenDecrypted.toString(CryptoJS.enc.Utf8);
            processMember(contactId);
        },
        [processMember, convertTokenToBase64]
    );

    useEffect(() => {
        const token = getQueryParameter(ELIGIBILITY_CHECKER_QUERY_KEY);

        if (!token) {
            throw new Error('NO PARAMETER PROVIDED');
        }

        processToken(token);
    }, [processToken]);

    return (
        <Container className={Styles.Container}>
            <Row className={'h-100'}>
                <Col className={'d-flex flex-column justify-content-center align-items-center'}>
                    <img
                        alt="error-boundary"
                        src={IMAGES.WCLogoLoading}
                        className={`${Styles.WCLogoLoading} ${Styles.Bounce}`}
                    />

                    <div className={`d-flex flex-column ${Styles.SmallMessage} pt-4 text-center`}>
                        <span className={'pb-3'}>Loading...</span>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
