import { EventFiltersModel, EventModel } from '@/events/models';
import { DEFAULT_OPTION, EventFilters, RegistrationStatus, ViewMode } from '@/events/reducer/types';

import { Boundaries } from '@/services/geolocation/types';

import {
    DEFAULT_LOCATION,
    DEFAULT_MILE_RADIUS,
    DEFAULT_PAGE_NUMBER,
    DEFAULT_PAGE_SIZE,
    DEFAULT_PREVIOUS_PAGE_NUMBER,
} from '@/constants/defaults';

import { VenueType } from '@/venues/models';

export interface EventsState {
    isLoadingEvents: boolean;
    isLoadingEventsForMap: boolean;
    fetchedEvents: EventModel[];
    fetchedEventsForMap: EventModel[];
    filteredEventsForMap: EventModel[];
    filteredEventsForList: EventModel[];
    fetchedUpcomingWeeklyEvents: EventModel[];
    filteredEventsByUser: EventModel[];
    selectedEvent: EventModel | undefined;
    filters: EventFilters;
    numberOfAvailableEvents: number;
    numberOfRegisteredEvents: number;
    viewMode: ViewMode;
    usStates: string[];
    registrationStatus: RegistrationStatus;
    mapBoundaries: Boundaries | undefined;
    isFiltersModalDisplayed: boolean;
    totalNumberOfNationwideEvents: number;
    totalNumberOfNonNationwideEvents: number;
    numberOfShownNationwideEvents: number;
    numberOfShownNonNationwideEvents: number;
    hasZipcodeBeingInput: boolean;
    hasFirstCorrectZipcodeBeenInputted: boolean;
    allEventIds: string[];
    eventFiltersPreviouslySetByAuthenticatedUser?: EventFiltersModel;
}

export const initialEventsState: EventsState = {
    isLoadingEvents: true,
    isLoadingEventsForMap: true,
    fetchedEvents: [],
    fetchedEventsForMap: [],
    filteredEventsForMap: [],
    filteredEventsForList: [],
    fetchedUpcomingWeeklyEvents: [],
    filteredEventsByUser: [],
    selectedEvent: undefined,
    filters: {
        previousPage: DEFAULT_PREVIOUS_PAGE_NUMBER,
        page: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
        eventIds: [],
        searchText: undefined,
        client: DEFAULT_OPTION.value,
        states: [DEFAULT_OPTION.value],
        venueTypes: [VenueType.VIRTUAL],
        languages: [DEFAULT_OPTION.value],
        zipcode: undefined,
        onlyRegisteredEvents: false,
        campaigns: [],
        mileRadius: DEFAULT_MILE_RADIUS,
        latitude: DEFAULT_LOCATION.latitude,
        longitude: DEFAULT_LOCATION.longitude,
    },
    numberOfAvailableEvents: 0,
    numberOfRegisteredEvents: 0,
    viewMode: ViewMode.MAP,
    usStates: [],
    registrationStatus: RegistrationStatus.VIEWING_EVENTS,
    mapBoundaries: undefined,
    isFiltersModalDisplayed: false,
    totalNumberOfNationwideEvents: 0,
    totalNumberOfNonNationwideEvents: 0,
    numberOfShownNationwideEvents: 0,
    numberOfShownNonNationwideEvents: 0,
    hasZipcodeBeingInput: false,
    hasFirstCorrectZipcodeBeenInputted: false,
    allEventIds: [],
};
