import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import { v4 as generateUUIDV4 } from 'uuid';
import { getNewToken, isTokenValid } from '@/authentication/api';
import { AuthenticatedUserModel } from '@/authentication/models';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions } from '@/authentication/reducer';
import { RootState } from '@/store';
import { TOKEN_PROP_NAME } from '@/constants/local-storage';

type UseAuthorizationParams = {
    storeAuthenticatedUser: (authenticatedUser: AuthenticatedUserModel) => void;
};

export const useAuthorization = ({ storeAuthenticatedUser }: UseAuthorizationParams) => {
    const dispatch = useDispatch();

    const isLoadingAuthentication = useSelector<RootState, boolean>(
        (state) => state.authentication.isLoadingAuthentication
    );

    const processCreationOfToken = useCallback(async (contactId: string) => {
        dispatch(authenticationActions.setLoadingAuthentication(true));
        const newToken = await getNewToken(contactId, generateUUIDV4());
        localStorage.setItem(TOKEN_PROP_NAME, newToken);
        checkTokenValidity();
    }, []);

    const checkTokenValidity = useCallback(async (): Promise<void> => {
        try {
            const authenticatedUser = await isTokenValid();
            storeAuthenticatedUser(authenticatedUser);
        } catch (error) {
            console.log(error);
        }
        dispatch(authenticationActions.setLoadingAuthentication(false));
    }, [storeAuthenticatedUser]);

    const getToken = useCallback((): string | null => {
        return localStorage.getItem(TOKEN_PROP_NAME);
    }, []);

    const clearToken = useCallback(() => {
        localStorage.removeItem(TOKEN_PROP_NAME);
    }, []);

    useEffect(() => {
        const token: string | null = localStorage.getItem(TOKEN_PROP_NAME);

        if (token !== null) {
            checkTokenValidity();
        } else {
            dispatch(authenticationActions.setLoadingAuthentication(false));
        }
    }, []);

    return {
        isLoadingAuthentication,
        processCreationOfToken,
        getToken,
        clearToken,
    };
};
