import { EventFiltersModel, EventModel } from '@/events/models';
import { httpClient } from '@/services/http-client';
import { eventApiToEventModel } from '@/events/mappers/events-mapper';
import {
    ContactMatchingBody,
    EventApi,
    EventFiltersResponseApi,
    EventRegistrationBody,
    GetEventsRequest,
    MatchContactResponse,
    MatchedContact,
    UpdateCommunicationPreferenceRequest,
} from '@/events/api/types';
import { ApiException } from '@/services/http-client/types';

interface EventsListResponse {
    events: EventApi[];
    total: number;
}

interface DividedEventsListResponse {
    virtual_events: EventApi[];
    non_virtual_events: EventApi[];
}

export const getEvents = async (
    request: GetEventsRequest
): Promise<{
    virtualEvents: EventModel[];
    inPersonEvents: EventModel[];
}> => {
    try {
        const response = await httpClient.requestFull<DividedEventsListResponse>({
            method: 'POST',
            url: `/selfserve/events`,
            data: request,
        });

        return {
            virtualEvents: response.data.virtual_events.map((event, index) =>
                eventApiToEventModel(event, index)
            ),
            inPersonEvents: response.data.non_virtual_events.map((event, index) =>
                eventApiToEventModel(event, index)
            ),
        };
    } catch (error) {
        console.log('ERROR', error);
        return {
            virtualEvents: [],
            inPersonEvents: [],
        };
    }
};

export const getEventsForMap = async (): Promise<{
    events: EventModel[];
    total: number;
}> => {
    try {
        const response = await httpClient.requestFull<EventsListResponse>({
            method: 'POST',
            url: `/selfserve/map/events`,
        });

        return {
            events: response.data.events.map((event, index) => eventApiToEventModel(event, index)),
            total: response.data.total,
        };
    } catch (error) {
        console.log('ERROR', error);
        return {
            events: [],
            total: 0,
        };
    }
};

export const matchContact = async (
    body: ContactMatchingBody
): Promise<MatchedContact | undefined> => {
    let matchedContact: MatchedContact | undefined;

    try {
        const response = await httpClient.requestFull<MatchContactResponse>({
            method: 'POST',
            url: '/selfserve/match?type=matching',
            data: body,
        });

        matchedContact =
            response.status === 200 && response.data.contacts.length > 0
                ? response.data.contacts[0]
                : undefined;
    } catch (error: any) {
        if ((error as ApiException).response.status === 404) {
            return undefined;
        }

        throw error;
    }

    return matchedContact;
};

export const registerForEvent = async (body: EventRegistrationBody): Promise<void> => {
    await httpClient.requestFull({
        method: 'POST',
        url: '/selfserve/events/registration',
        data: body,
    });
};

export const updateCommunicationPreferences = async (
    body: UpdateCommunicationPreferenceRequest
): Promise<boolean> => {
    const response = await httpClient.requestFull<MatchContactResponse>({
        method: 'PUT',
        url: '/selfserve/events/registration',
        data: body,
    });

    return response.status === 200;
};

export const getEventFiltersFromAuthenticatedUser = async (): Promise<EventFiltersModel> => {
    try {
        const response = await httpClient.requestFull<EventFiltersResponseApi>({
            method: 'GET',
            url: `/selfserve/event-filters`,
        });

        return {
            usState: response.data.us_state,
            zipCode: response.data.zip_code,
            language: response.data.language,
            client: response.data.client
                ? {
                      label: response.data.client.label,
                      parameter: response.data.client.parameter,
                      nameOnEvents: response.data.client.names_on_events,
                  }
                : undefined,
        };
    } catch (error) {
        throw error;
    }
};

export const saveEventFiltersForAuthenticatedUser = async (
    request: EventFiltersModel
): Promise<void> => {
    try {
        await httpClient.requestFull<EventFiltersResponseApi>({
            method: 'POST',
            url: `/selfserve/event-filters`,
            data: {
                us_state: request.usState,
                zip_code: request.zipCode,
                language: request.language,
                client_parameter: request.client?.parameter,
            },
        });
    } catch (error) {
        throw error;
    }
};
