import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Styles from './Styles.module.scss';
import { useEventsFilteringOperations } from '@/events/components/events-filtering/use-events-filtering-operations';
import { WebFiltering } from '@/events/components/events-filtering/components/web-filtering';
import { MobileFiltering } from '@/events/components/events-filtering/components/mobile-filtering';
import { FiltersModal } from '@/events/components/events-filtering/components/filters-modal';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { RootState } from '@/store';
import { eventsActions } from '@/events/reducer';
import { useEventFiltersInitializer } from '@/events/hooks/use-event-filters-initializer';

type Props = {
    numberOfResults: number;
};

export const EventsFiltering: FunctionComponent<Props> = ({ numberOfResults }) => {
    const { width } = useWindowDimensions();

    const dispatch = useDispatch();

    const isFiltersModalDisplayed = useSelector<RootState, boolean>(
        (state) => state.events.isFiltersModalDisplayed
    );

    const eventsFilteringOperations = useEventsFilteringOperations();

    const hideStateAndZipcodeFilters = useMemo(() => width < 1384 && width >= 768, [width]);
    const hideEventTypeFilter = useMemo(() => width >= 1054, [width]);

    const filtersButtonClickHandler = useCallback(() => {
        dispatch(eventsActions.displayFiltersModal(!isFiltersModalDisplayed));
    }, [dispatch, isFiltersModalDisplayed]);

    useEventFiltersInitializer();

    return (
        <>
            {isFiltersModalDisplayed && (
                <FiltersModal
                    eventsFilteringOperations={eventsFilteringOperations}
                    onClose={filtersButtonClickHandler}
                    isOpen={isFiltersModalDisplayed}
                    numberOfResults={numberOfResults}
                    hideStateFilter={hideStateAndZipcodeFilters}
                    hideZipcodeInput={hideStateAndZipcodeFilters}
                    hideEventTypeFilter={hideEventTypeFilter}
                    isLargeScreen={width >= 768}
                />
            )}

            <div className={`${Styles.EventsFilteringContainer} px-0 pb-2`}>
                {width >= 768 && (
                    <div
                        className={`${Styles.WebContainer} w-100 d-xl-block d-lg-block d-md-block h-100 px-3`}
                    >
                        <WebFiltering
                            eventsFilteringOperations={eventsFilteringOperations}
                            onFilterButtonClick={filtersButtonClickHandler}
                        />
                    </div>
                )}

                {width < 768 && (
                    <div className={'d-sm-block d-block h-100 px-3'}>
                        <MobileFiltering
                            eventsFilteringOperations={eventsFilteringOperations}
                            onFilterButtonClick={filtersButtonClickHandler}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
