import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { EventFilters, RegistrationStatus } from '@/events/reducer/types';
import { useCallback, useState } from 'react';
import { eventsActions, fetchEvents, fetchEventsForMap } from '@/events/reducer';
import { EventModel } from '@/events/models';
import { useRegistrationOperations } from '@/events/pages/event-registration/components/use-registration-operations';

type UseRegistrationFromEventsListProps = {
    event: EventModel;
};

export const useRegistrationFromEventsList = ({ event }: UseRegistrationFromEventsListProps) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const errorHandler = useCallback(() => {
        setLoading(false);
    }, [setLoading]);

    const successHandler = useCallback(() => {
        setLoading(false);
        dispatch(eventsActions.selectEvent(event));
        dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.AFTER_CONFIRMATION));
    }, [dispatch, event, setLoading]);

    const { register } = useRegistrationOperations({
        authenticatedUser,
        clients: event.clients,
        onError: errorHandler,
        onSuccess: successHandler,
    });

    const onAddMeToGuestList = useCallback(async () => {
        setLoading(true);
        await register(event.id, true, true, true, false);

        dispatch(eventsActions.updateAllEventIds([]));

        // @ts-ignore
        dispatch(fetchEvents(filters));
        // @ts-ignore
        dispatch(fetchEventsForMap());
    }, [register, event.id, filters]);

    const onUnregister = useCallback(async () => {
        setLoading(true);
        await register(event.id, true, true, true, true);

        dispatch(eventsActions.updateAllEventIds([]));
        dispatch(eventsActions.selectEvent(undefined));

        // @ts-ignore
        dispatch(fetchEvents(filters));
        // @ts-ignore
        dispatch(fetchEventsForMap());
    }, [register, event.id, filters]);

    return {
        onAddMeToGuestList,
        loading,
        onUnregister,
    };
};
