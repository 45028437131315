import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { useEventsFilteringOperations } from '@/events/components/events-filtering/use-events-filtering-operations';
import { useCallback, useEffect } from 'react';
import {
    LOCAL_EVENT_FILTER_HEALTH_PLAN,
    LOCAL_EVENT_FILTER_LANGUAGE,
    LOCAL_EVENT_FILTER_US_STATE,
    LOCAL_EVENT_FILTER_ZIP_CODE,
} from '@/constants/local-storage';
import { getEventFiltersFromAuthenticatedUser } from '@/events/api';
import { eventsActions } from '@/events/reducer';

export const useEventFiltersInitializer = () => {
    const isLoadingAuthentication = useSelector<RootState, boolean>(
        (state) => state.authentication.isLoadingAuthentication
    );

    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const eventsFilteringOperations = useEventsFilteringOperations();

    const dispatch = useDispatch();

    const loadFilters = useCallback(
        (
            usStateFilter: string | undefined | null,
            languageFilter: string | undefined | null,
            zipCodeFilter: string | undefined | null,
            healthPlanFilter: string | undefined | null
        ) => {
            if (usStateFilter) {
                const usStatesToFilter = usStateFilter.split(';');
                const optionsFound = eventsFilteringOperations.options.states.filter((item) =>
                    usStatesToFilter.includes(item.value)
                );
                if (optionsFound.length !== 0) {
                    eventsFilteringOperations.handlers.statesChangeHandler(optionsFound);
                }
            }

            if (languageFilter) {
                const languagesToFilter = languageFilter.split(';');
                const optionsFound = eventsFilteringOperations.options.languages.filter((item) =>
                    languagesToFilter.includes(item.value)
                );
                eventsFilteringOperations.handlers.languagesChangeHandler(optionsFound);
            }

            if (zipCodeFilter) {
                eventsFilteringOperations.handlers.zipcodeChangeHandler(zipCodeFilter);
            }

            if (healthPlanFilter) {
                const optionFound = eventsFilteringOperations.options.clients.find(
                    (item) => item.value === healthPlanFilter
                );

                if (optionFound) {
                    eventsFilteringOperations.handlers.clientChangeHandler(optionFound);
                }
            }
        },
        [
            eventsFilteringOperations.options.states,
            eventsFilteringOperations.options.languages,
            eventsFilteringOperations.options.clients,
            eventsFilteringOperations.handlers.zipcodeChangeHandler,
            eventsFilteringOperations.handlers.statesChangeHandler,
            eventsFilteringOperations.handlers.clientChangeHandler,
            eventsFilteringOperations.handlers.languagesChangeHandler,
        ]
    );

    const loadFiltersFromLocalStorage = useCallback(() => {
        const localUsStateFilter = localStorage.getItem(LOCAL_EVENT_FILTER_US_STATE);
        const localLanguageFilter = localStorage.getItem(LOCAL_EVENT_FILTER_LANGUAGE);
        const localZipCodeFilter = localStorage.getItem(LOCAL_EVENT_FILTER_ZIP_CODE);
        const localHealthPlanFilter = localStorage.getItem(LOCAL_EVENT_FILTER_HEALTH_PLAN);

        loadFilters(
            localUsStateFilter,
            localLanguageFilter,
            localZipCodeFilter,
            localHealthPlanFilter
        );
    }, [loadFilters]);

    const loadFiltersFromAuthenticatedUser = useCallback(async () => {
        const eventFilters = await getEventFiltersFromAuthenticatedUser();

        loadFilters(
            eventFilters.usState,
            eventFilters.language,
            eventFilters.zipCode,
            eventFilters.client?.parameter
        );

        dispatch(eventsActions.updateEventFiltersPreviouslySetByAuthenticatedUser(eventFilters));
    }, [loadFilters]);

    useEffect(() => {
        if (!isLoadingAuthentication && !authenticatedUser) {
            loadFiltersFromLocalStorage();
        } else if (!isLoadingAuthentication && authenticatedUser) {
            loadFiltersFromAuthenticatedUser();
        }
    }, [
        isLoadingAuthentication,
        authenticatedUser,
        loadFiltersFromLocalStorage,
        loadFiltersFromAuthenticatedUser,
    ]);
};
