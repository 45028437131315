import { AuthenticatedUserModel } from '@/authentication/models';

export interface QueryParameters {
    clients: string[];
    campaigns: string[];
    eventId: string | undefined;
    state: string | undefined;
}

export interface AuthenticationState {
    isLoadingAuthentication: boolean;
    authenticatedUser: AuthenticatedUserModel | undefined;
    sessionId: string | undefined;
    queryParameters: QueryParameters;
}

export const initialAuthenticationState: AuthenticationState = {
    isLoadingAuthentication: true,
    authenticatedUser: undefined,
    sessionId: undefined,
    queryParameters: {
        clients: [],
        campaigns: [],
        eventId: undefined,
        state: undefined,
    },
};
